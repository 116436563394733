// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-certificate-page-js": () => import("/opt/build/repo/src/templates/certificate-page.js" /* webpackChunkName: "component---src-templates-certificate-page-js" */),
  "component---src-templates-failed-test-page-js": () => import("/opt/build/repo/src/templates/failed-test-page.js" /* webpackChunkName: "component---src-templates-failed-test-page-js" */),
  "component---src-templates-agreement-page-js": () => import("/opt/build/repo/src/templates/agreement-page.js" /* webpackChunkName: "component---src-templates-agreement-page-js" */),
  "component---src-templates-questions-page-js": () => import("/opt/build/repo/src/templates/questions-page.js" /* webpackChunkName: "component---src-templates-questions-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

